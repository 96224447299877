<template>
  <profile-edit-create :organization-id="$auth.me.organization_id" />
</template>
<script>
import ProfileEditCreate from '~/pages/shared/profiles/partials/EditCreate';

export default {
  components: {
    ProfileEditCreate,
  },
};
</script>
